import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import * as Yup from "yup";

import {
    Grid,
    Divider as MuiDivider,
    Typography,
    FormControl,
    CardContent,
    Card,
    Alert as MuiAlert,
    Button,
    TextField as MuiTextField, FormLabel, RadioGroup, FormControlLabel, Radio
} from "@mui/material";

import {Formik} from "formik";
import {useUpdateUserMutation, useUserQuery} from "../../graphql/auth/auth.graphql-gen";
import SnackbarNotification from "../../components/SnackbarNotification";

//styling
const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);



function Profile() {
    const {data: data, error: error, loading: loading} = useUserQuery({
        variables: {model: 'User'}
    });
    const [updateUser] = useUpdateUserMutation();
    const [updated, setUpdated] = useState(false);
    const [valueGender, setValueGender] = useState<string>( '' );


    const validationSchema = Yup.object().shape({
        firstName: Yup.string().max(255).required("First name is required"),
        lastName: Yup.string().max(255).required("Last name is required"),
        email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
    })

    useEffect(() => {
        if (data && data.user && data.user.user && data.user.user.gender) {
            setValueGender(data.user.user.gender);
        }
    }, [data]);

    // Handle error
    if (error) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueGender((event.target as HTMLInputElement).value);
    };


    if (data && data.user && data.user.user) {
        return (
            <React.Fragment>
                <Helmet title="Profile"/>
                <Typography variant="h3" gutterBottom display="inline">
                    Profile
                </Typography>
                <Divider my={6}/>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Formik
                            initialValues={{
                                firstName: data.user.user.firstName,
                                lastName: data.user.user.lastName,
                                email: data.user.user.email,
                                gender: data.user.user.gender
                                //submit: false,
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(values, {setErrors, setStatus, setSubmitting}) => {
                                try {
                                    updateUser({
                                        variables: {
                                            model: 'User',
                                            data: values
                                        }
                                    }).then(r => {
                                        setUpdated(true)

                                        setTimeout(()=> {
                                            setUpdated(false)
                                        },3000)
                                    });
                                } catch (error: any) {
                                    const message = error.message || "Something went wrong";

                                    setStatus({success: false});
                                    //setErrors({submit: message});
                                    setSubmitting(false);
                                }
                            }}
                        >
                            {({
                                  errors,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  touched,
                                  values,
                              }) => (
                                <Card>
                                    <CardContent>
                                        <form noValidate onSubmit={handleSubmit}>
                                            <Grid container spacing={6}>
                                                <Grid item md={6} mb={5}>
                                                    <FormControl>
                                                        <FormLabel
                                                            id="gender">Gender</FormLabel>
                                                        <RadioGroup
                                                            aria-labelledby="gender"
                                                            name="gender"
                                                            value={valueGender}
                                                            onChange={(e) => {
                                                                handleRadioChange(e);
                                                                handleChange(e)
                                                            }}
                                                        >
                                                            <FormControlLabel value="1" control={<Radio/>}
                                                                              label="Female"/>
                                                            <FormControlLabel value="2" control={<Radio/>}
                                                                              label="Male"/>
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={6}>
                                                <Grid item md={6} mb={5}>
                                                    <TextField
                                                        type="text"
                                                        name="firstName"
                                                        label="First name"
                                                        value={values.firstName}
                                                        error={Boolean(touched.firstName && errors.firstName)}
                                                        fullWidth
                                                        helperText={touched.firstName && errors.firstName}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        my={3}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={6}>
                                                <Grid item md={6} mb={5}>
                                                    <TextField
                                                        type="text"
                                                        name="lastName"
                                                        label="Last name"
                                                        value={values.lastName}
                                                        error={Boolean(touched.lastName && errors.lastName)}
                                                        fullWidth
                                                        helperText={touched.lastName && errors.lastName}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        my={3}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={6}>
                                                <Grid item md={6} mb={5}>
                                                    <TextField
                                                        type="email"
                                                        name="email"
                                                        label="Email address"
                                                        value={values.email}
                                                        error={Boolean(touched.email && errors.email)}
                                                        fullWidth
                                                        helperText={touched.email && errors.email}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        my={3}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={false}
                                            >
                                                Save profile
                                            </Button>
                                        </form>
                                    </CardContent>
                                </Card>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
                {updated &&
                    <SnackbarNotification message="User is updated" open={updated}/>
                }
            </React.Fragment>
        );
    }
    return null
}

export default Profile;