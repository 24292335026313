import React, {useEffect} from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";

import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";

import { AuthProvider } from "./contexts/JWTContext";
import { useIdleTimer } from 'react-idle-timer';
import {useRefreshAccessTokenMutation} from "./graphql/auth/auth.graphql-gen";

// @ts-ignore
const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point")!,
});

function App() {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  const [refreshToken, {data, error, loading}] = useRefreshAccessTokenMutation({
    onCompleted: (res) => {

      if (res.refreshAccessToken && res.refreshAccessToken.accessToken && res.refreshAccessToken.expDate) {
        window.localStorage.setItem('token', res.refreshAccessToken.accessToken)
        window.localStorage.setItem('expDate', res.refreshAccessToken.expDate)
      }else{
        localStorage.clear();
      }
    }
  });

  useEffect(() => {
    refreshToken();
  }, []);

  window.setTimeout(() => {
    refreshToken();
  }, 60*1000*58);

  const handleOnIdle = (event:any) => {
    localStorage.clear();
  }

  // const { getRemainingTime, getLastActiveTime } = useIdleTimer({
  //   timeout: 1000 * 60 * 45,
  //   onIdle: handleOnIdle,
  //   debounce: 500
  // })




  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | Swiss Pim"
        defaultTitle="Swiss Pim"
      />
      <Provider store={store}>

        <StylesProvider jss={jss}>

            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={createTheme(theme)}>
                <ThemeProvider theme={createTheme(theme)}>
                  <AuthProvider>{content}</AuthProvider>
                </ThemeProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>

        </StylesProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
